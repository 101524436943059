import { useEffect, useState } from "react"
import { http } from "utils/http"

import HomeBanner from "./Banner"
import Discover from "./Discover"
import NewArrival from "./NewArrival"
import BestSeller from "./BestSeller"
import SpecialOffer from "./SpecialOffer"
import Intrend from "./Intrend"
import Subscribe from "./Subscribe"

interface BannerType {
    image: string;
    heading: string;
    subHeading: string;
    btnText: string;
    btnLink: string;
}

interface DiscoverType {
    className?: string;
    image?: string;
    name?: string;
    desc?: string;
    color?: string;
}

interface ProductCardType {
    className?: string;
    itemClassName?: string;
    heading?: string;
    headingFontClassName?: string;
    headingClassName?: string;
    subHeading?: string;
    data?: Product[];
}

interface Product {
    id: number;
    name: string;
    price: number;
    image: string;
    description: string;
    category: string;
    tags: string[];
    link: "/product-detail/";
    variants?: ProductVariant[];
    variantType?: "color" | "image";
    sizes?: string[];
    allOfSizes?: string[];
    status?: "New in" | "Limited edition" | "Sold Out" | "50% Discount";
}

interface ProductVariant {
    id: number;
    name: string;
    thumbnail?: string;
    color?: string;
    featuredImage: string;
}

function Main()
{
    const [bannerData, setBannerData] = useState<BannerType[]>([])
    const [discoverData, setDiscoverData] = useState<DiscoverType[]>([])
    const [newArrivalData, setNewArrivalData] = useState<Product[]>([])
    const [hotSaleData, setHotSaleData] = useState<Product[]>([])
    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        const resBanner:any = await http.post('https://boonshop1989.com/BSBO/api/banner/list');
        if(resBanner?.Status === 'Success')
        {
            setBannerData(resBanner.Data)
        }

        const resShowCase:any = await http.post('https://boonshop1989.com/BSBO/api/product/show-case');
        if(resShowCase?.Status === 'Success')
        {
            setNewArrivalData(resShowCase?.Data?.ListNew ?? [])
            setHotSaleData(resShowCase?.Data?.ListHot ?? [])
        }

        // const response:any = await http.post('/home.php');
        // if(response?.discovers != null){
        //     setDiscoverData(response.discovers)
        // }
    }

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <HomeBanner data={bannerData} />
            <div className="h-10"></div>
            {/* <Discover data={discoverData} /> */}

            <NewArrival 
                className="container relative my-14 lg:my-16"
                data={newArrivalData} 
            />

            <BestSeller 
                className="container relative my-14 lg:my-16"
                data={hotSaleData} 
                heading="Best Seller"
                subHeading="Selling of the month"
            />

            {/* <SpecialOffer
                className="container relative my-14 lg:my-16"
            /> */}

            {/* <Intrend 
                className="container relative my-14 lg:my-16"
            /> */}

            {/* <Subscribe 
                className="container relative my-14 lg:my-16"
            /> */}
        </div>
    )
}

export default Main

