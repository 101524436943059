const removeComma = (num:string|number) => {
	if(num === undefined || num === null || num === '') return 0;
	return parseFloat(num.toString().replace(/,/g, ''));
};

const currencyFormat = (num:string|number, dicimal?:number) => {
	dicimal = dicimal === undefined ? 0 : dicimal;
	if (num === undefined || num === '') return '';
	num = removeComma(num);
	return num.toFixed(dicimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const numberFormat = (num:string|number, dicimal?:number) => {
	dicimal = dicimal === undefined ? 0 : dicimal;
	if (num === '') return '';
	num = removeComma(num);
	return num.toFixed(dicimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1');
};

export {
    removeComma,
    currencyFormat,
    numberFormat
}