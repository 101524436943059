import React, { FC, useState, useEffect } from "react";
import { http } from "utils/http"
import clsx from "clsx"
import { useSearchParams } from 'react-router-dom';
import {
    NoSymbolIcon,
    ClockIcon,
    SparklesIcon,
} from "@heroicons/react/24/outline";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "containers/ProductDetailPage/LikeSaveBtns";
import ModalPhotos from "containers/ProductDetailPage/ModalPhotos";
import ReviewItem from "components/ReviewItem";
import detail21JPG from "images/products/detail3-1.webp";
import detail22JPG from "images/products/detail3-2.webp";
import detail23JPG from "images/products/detail3-3.webp";
import detail24JPG from "images/products/detail3-4.webp";
import { PRODUCTS } from "data/data";
import IconDiscount from "components/IconDiscount";
import NcInputNumber from "components/NcInputNumber";
import BagIcon from "components/BagIcon";
import AccordionInfo from "containers/ProductDetailPage/AccordionInfo";
import Policy from "containers/ProductDetailPage/Policy";
import toast from "react-hot-toast";
import { StarIcon } from "@heroicons/react/24/solid";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import ModalViewAllReviews from "containers/ProductDetailPage/ModalViewAllReviews";
import NotifyAddTocart from "components/NotifyAddTocart";
import { numberFormat } from "utils/convertNumber";
import { stringToHTML } from "utils/helper"

export interface ProductDetailPage2Props {
    className?: string;
}

const Main: FC<ProductDetailPage2Props> = ({
    className = "",
}) => {
    let [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        console.log(searchParams.get('id'))
        getDetail()
    }, [])    
    
    const [productDetail, setProductDetail] = useState<any>([])
    const getDetail = async () => {
        // const response:any = await http.post('/home.php');
        // if(response?.productDetail != null){
        //     setProductDetail(response.productDetail)
        // }

        const detail:any = await http.post('https://boonshop1989.com/BSBO/api/product/show-detail', {ProductID:searchParams.get('id')});
        if(detail?.Status === 'Success')
        {
            console.log(detail)
            setProductDetail(detail.Data)
        }
    }

    const PRICE = 108;

    const [colorSelected, setColorSelected] = useState('');
    const [sizeSelected, setSizeSelected] = useState('');
    const [priceSelected, setPriceSelected] = useState(0);
    const [qualitySelected, setQualitySelected] = React.useState(1);

    useEffect(() => {
        setPriceSelected(productDetail?.Price)
    }, [productDetail])
    

    // useEffect(() => {
    //     const skuSelected = productDetail?.sku?.find((e:any) => e.attribute[0] === colorSelected && e.attribute[1] === sizeSelected)
    //     if(skuSelected !== undefined)
    //     {
    //         setPriceSelected(skuSelected.price)
    //     }
    // }, [colorSelected, sizeSelected])
    

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] = useState(false);
    const [openFocusIndex, setOpenFocusIndex] = useState(0);

    const handleOpenModal = (index: number) => {
        setIsOpen(true);
        setOpenFocusIndex(index);
    };

    const handleCloseModal = () => setIsOpen(false);

    const renderVariants = () => {
        if (!productDetail?.Variants?.Attribute1) {
            return null;
        }

        const label = productDetail?.Variants?.Attribute1?.Name
        const items = productDetail?.Variants?.Attribute1?.Values

        return (
            <div>
                <label htmlFor="">
                    <span className="text-sm font-medium">
                        {label} :
                        <span className="ml-1 font-semibold">
                            {colorSelected}
                        </span>
                    </span>
                </label>
                <div className="mt-3 gap-1">
                    {items.map((variant:string, index:number) => (
                        <div
                            key={index}
                            onClick={() => setColorSelected(variant)}
                            className={clsx([
                                "inline-block mr-1 last:mr-0 px-3 mb-2 rounded-2xl cursor-pointer border border-slate-300 dark:border-slate-600 hover:bg-neutral-50 dark:hover:bg-neutral-700",
                                colorSelected === variant && "!border-primary-6000 dark:!border-primary-500 !bg-primary-6000 dark:!bg-primary-500 text-white"
                            ])}
                        >
                            <div className="h-10 sm:h-11 flex items-center justify-center text-sm sm:text-base font-semibold select-none overflow-hidden z-0">
                                <div>{variant}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderSizeList = () => {
        if (!productDetail?.Variants?.Attribute2) {
            return null;
        }

        const label = productDetail?.Variants?.Attribute2?.Name
        const items = productDetail?.Variants?.Attribute2?.Values

        return (
            <div>
                <div className="flex justify-between font-medium text-sm">
                    <label htmlFor="">
                        <span className="">
                            {label} :
                            <span className="ml-1 font-semibold">{sizeSelected}</span>
                        </span>
                    </label>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="##"
                        className="text-primary-6000 hover:text-primary-500"
                    >
                        See sizing chart
                    </a>
                </div>
                <div className="grid grid-cols-4 gap-2 mt-3">
                    {items.map((size:string, index:number) => {
                        const isActive = size === sizeSelected;
                        const outStock = false;
                        return (
                            <div
                                key={index}
                                className={clsx([
                                    "relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 cursor-pointer border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700",
                                    isActive && "!bg-primary-6000 !border-primary-6000 text-white hover:!bg-primary-6000",
                                    outStock && "!text-opacity-20 dark:!text-opacity-20 cursor-not-allowed"
                                ])}
                                onClick={() => {
                                    if (outStock) {
                                        return;
                                    }
                                    setSizeSelected(size);
                                }}
                            >
                                {size}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    // const notifyAddTocart = () => {
    //     toast.custom(
    //         (t) => (
    //             <NotifyAddTocart
    //                 productImage={productDetail?.images?.[0]}
    //                 qualitySelected={qualitySelected}
    //                 show={t.visible}
    //                 sizeSelected={sizeSelected}
    //                 variantActive={colorSelected}
    //             />
    //         ),
    //         { position: "top-right", id: "nc-product-notify", duration: 3000 }
    //     );
    // };

    const renderStatus = () => {
        if (!productDetail?.Status) {
            return null;
        }
        const CLASSES =
            "text-sm flex items-center text-slate-700 text-slate-900 dark:text-slate-300";
        if (productDetail?.Status === "New in") {
            return (
                <div className={CLASSES}>
                    <SparklesIcon className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{productDetail?.Status}</span>
                </div>
            );
        }
        if (productDetail?.Status === "50% Discount") {
            return (
                <div className={CLASSES}>
                    <IconDiscount className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{productDetail?.Status}</span>
                </div>
            );
        }
        if (productDetail?.Status === "Sold Out") {
            return (
                <div className={CLASSES}>
                    <NoSymbolIcon className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{productDetail?.Status}</span>
                </div>
            );
        }
        if (productDetail?.Status === "Limited edition") {
            return (
                <div className={CLASSES}>
                    <ClockIcon className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{productDetail?.Status}</span>
                </div>
            );
        }
        return null;
    };

    const renderSectionBoxAttribute = () => {
        return (
            <div className="listingSectionSidebar__wrap lg:shadow-lg">
                <div className="space-y-7 lg:space-y-8">
                    {/* PRICE */}
                    <div className="">
                        {/* ---------- 1 HEADING ----------  */}
                        <div className="flex items-center justify-between space-x-5">
                            <div className="flex text-2xl font-semibold">
                                {priceSelected}
                            </div>

                            <a
                                href="#reviews"
                                className="flex items-center text-sm font-medium"
                            >
                                <div className="">
                                    <StarIcon className="w-5 h-5 pb-[1px] text-orange-400" />
                                </div>
                                <span className="ml-1.5 flex">
                                    <span>4.9 </span>
                                    <span className="mx-1.5">·</span>
                                    <span className="text-slate-700 dark:text-slate-400 underline">
                                        142 reviews
                                    </span>
                                </span>
                            </a>
                        </div>

                        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
                        <div className="mt-6 space-y-7 lg:space-y-8">
                            <div className="">{renderVariants()}</div>
                            <div className="">{renderSizeList()}</div>
                        </div>
                    </div>
                    {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
                    {/* <div className="flex space-x-3.5">
                        <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
                            <NcInputNumber
                                defaultValue={qualitySelected}
                                onChange={setQualitySelected}
                            />
                        </div>
                        <ButtonPrimary
                            className="flex-1 flex-shrink-0"
                            onClick={notifyAddTocart}
                        >
                            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
                            <span className="ml-3">Add to cart</span>
                        </ButtonPrimary>
                    </div> */}

                    {/* SUM */}
                    {/* <div className="hidden sm:flex flex-col space-y-4 ">
                        <div className="space-y-2.5">
                            <div className="flex justify-between text-slate-600 dark:text-slate-300">
                                <span className="flex">
                                    <span>{`$${PRICE.toFixed(2)}  `}</span>
                                    <span className="mx-2">x</span>
                                    <span>{`${qualitySelected} `}</span>
                                </span>

                                <span>{`$${(PRICE * qualitySelected).toFixed(2)}`}</span>
                            </div>
                            <div className="flex justify-between text-slate-600 dark:text-slate-300">
                                <span>Tax estimate</span>
                                <span>$0</span>
                            </div>
                        </div>
                        <div className="border-b border-slate-200 dark:border-slate-700"></div>
                        <div className="flex justify-between font-semibold">
                            <span>Total</span>
                            <span>{`$${(PRICE * qualitySelected).toFixed(2)}`}</span>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    };

    const renderSectionHeader = () => {
        return (
            <div className="listingSection__wrap !space-y-6 !pb-2">
                <div>
                    <h2 className="text-2xl md:text-3xl font-semibold">
                        {productDetail?.ProductName}
                    </h2>
                    <div className="flex items-center mt-4 sm:mt-5">
                        <a
                            href="#reviews"
                            className="hidden sm:flex items-center text-sm font-medium "
                        >
                            <div className="">
                                <StarIcon className="w-5 h-5 pb-[1px] text-slate-800 dark:text-slate-200" />
                            </div>
                            <span className="ml-1.5">
                                <span>4.9</span>
                                <span className="mx-1.5">·</span>
                                <span className="text-slate-700 dark:text-slate-400 underline">
                                    142 reviews
                                </span>
                            </span>
                        </a>
                        <span className="hidden sm:block mx-2.5">·</span>
                        {renderStatus()}

                        <div className="ml-auto">
                            <LikeSaveBtns />
                        </div>
                    </div>
                </div>
                {/* Attribute Box */}
                <div className="block lg:hidden">{renderSectionBoxAttribute()}</div>
            </div>
        );
    };

    const renderSectionDetail = () => {
        return (
            <div className="listingSection__wrap !border-b-0 !pb-0 !mt-4">
                <h2 className="text-2xl font-semibold">Product details</h2>
                <div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl">
                    <div dangerouslySetInnerHTML={{__html: productDetail?.Description}} />
                </div>
            </div>
        );
    };

    return (
        <div
            className={`ListingDetailPage nc-ProductDetailPage2 ${className}`}
            data-nc-id="ProductDetailPage2"
        >
            {/* SINGLE HEADER */}
            <>
                <header className="container mt-8 sm:mt-10">
                    <div className="relative ">
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-3 lg:gap-6">
                            <div
                                className="col-span-2 md:col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                                onClick={() => handleOpenModal(0)}
                            >
                                <NcImage
                                    containerClassName="aspect-w-3 aspect-h-4 md:absolute md:inset-0"
                                    className="object-cover w-full h-full rounded-md sm:rounded-xl"
                                    src={productDetail?.Images?.[0]}
                                />
                                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-40 transition-opacity"></div>
                            </div>

                            {/*  */}
                            <div
                                className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                                onClick={() => handleOpenModal(1)}
                            >
                                <NcImage
                                    containerClassName="absolute inset-0"
                                    className="object-cover w-full h-full rounded-md sm:rounded-xl"
                                    src={productDetail?.Images?.[1]}
                                />
                                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-40 transition-opacity"></div>
                            </div>

                            {/*  */}
                            {[productDetail?.Images?.[2], productDetail?.Images?.[3]].map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 2 ? "block" : ""
                                        }`}
                                >
                                    <NcImage
                                        containerClassName="aspect-w-6 aspect-h-5 lg:aspect-h-4"
                                        className="object-cover w-full h-full rounded-md sm:rounded-xl "
                                        src={item || ""}
                                    />

                                    {/* OVERLAY */}
                                    <div
                                        className="absolute inset-0 bg-slate-900 bg-opacity-20 opacity-0 hover:opacity-60 transition-opacity cursor-pointer"
                                        onClick={() => handleOpenModal(index + 2)}
                                    />
                                </div>
                            ))}
                        </div>
                        <div
                            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-white text-slate-500 cursor-pointer hover:bg-slate-200 z-10"
                            onClick={() => handleOpenModal(0)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                />
                            </svg>
                            <span className="ml-2 text-neutral-800 text-sm font-medium">
                                Show all photos
                            </span>
                        </div>
                    </div>
                </header>
                {/* MODAL PHOTOS */}
                <ModalPhotos
                    images={productDetail?.Images ?? []}
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                    initFocus={openFocusIndex}
                    uniqueClassName="nc-ProductDetailPage2__modalPhotos"
                />
            </>

            {/* MAIn */}
            <main className="container relative z-10 mt-9 sm:mt-11 flex ">
                {/* CONTENT */}
                <div className="w-full lg:w-3/5 xl:w-2/3 space-y-10 lg:pr-14 lg:space-y-14">
                    {renderSectionHeader()}
                    {renderSectionDetail()}
                </div>

                {/* SIDEBAR */}
                <div className="flex-grow">
                    <div className="hidden lg:block lg:max-w-[400px] sticky top-28">
                        {renderSectionBoxAttribute()}
                    </div>
                </div>
            </main>

            {/* OTHER SECTION */}
            <div className="container pb-24 lg:pb-28 pt-14 space-y-14">
                <hr className="border-slate-200 dark:border-slate-700" />

                <SectionSliderProductCard
                    heading="Customers also purchased"
                    subHeading=""
                    headingFontClassName="text-2xl font-semibold"
                    headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
                />
            </div>

            {/* MODAL VIEW ALL REVIEW */}
            <ModalViewAllReviews
                show={isOpenModalViewAllReviews}
                onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
            />
        </div>
    );
};

export default Main;
