import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo/logo.jpg";
import logoLightImg from "images/logo/logo.jpg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "flex-shrink-0",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-slate-600 ${className}`}
    >
      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {img ? (
        <div className="flex items-center gap-2">
          <img
            className={`block max-h-16 sm:max-h-20 ${imgLight ? "dark:hidden" : ""
              }`}
            src={img}
            alt="Logo"
          />
          <div className="hidden sm:block flex flex-col">
            <div className="w-full text-center font-black">Boon Shop</div>
            <div className="w-full text-center font-black">1989</div>
          </div>
        </div>
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-8 sm:max-h-10 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )}
    </Link>
  );
};

export default Logo;
