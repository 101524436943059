import axios from 'axios'

const instance = axios.create({
	baseURL: 'https://boonshop1989.com/',
	headers: {
	  "Content-Type": "application/json",
	},
});

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;
instance.interceptors.request.use(
	async config => {
		// const token = localStorage.getItem('token')
		// if (token) {
		// 	// config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
		// 	config.headers["x-access-token"] = token; // for Node.js Express back-end
		// }
		if (!isAbsoluteURLRegex.test(config.url as string)) {
			
		}
		
		config.timeout = 30000; // 30 Second
		return config;
	},
	async error => {
		return Promise.reject(error)
	}
);

instance.interceptors.response.use(
	response => {
		if(response.status === 200) return response.data;
		return response;
	},
	async error => {
		const originalConfig = error.config
		// console.log(originalConfig)
		if (error.response) {
			return Promise.reject(error.response);
			// Access Token was expired
			// if (error.response.status === 401 && !originalConfig._retry) {
			// 	originalConfig._retry = true;

			// if (error.response.status === 403 && error.response.data) {
			// 	return Promise.reject(error.response.data);
			// }
		} else {
			return Promise.reject({
				status: 'Error',
				msg: 'Cannot connect to server, Please try again.'
			});
		}
		return Promise.reject(error);
	}
);

export const http = instance;