import { FC, useEffect, useState } from "react";
import { http } from "utils/http"
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import ProductCard from "components/ProductCard2";
import { PRODUCTS } from "data/data";
import SidebarFilters from "containers/SidebarFilters";
import TabFilters from "containers/TabFilters";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface PageCollection2Props {
    className?: string;
}

interface Product {
    id: number;
    name: string;
    price: number;
    image: string;
    description: string;
    category: string;
    tags: string[];
    link: "/product-detail/";
    variants?: ProductVariant[];
    variantType?: "color" | "image";
    sizes?: string[];
    allOfSizes?: string[];
    status?: "New in" | "Limited edition" | "Sold Out" | "50% Discount";
}

interface ProductVariant {
    id: number;
    name: string;
    thumbnail?: string;
    color?: string;
    featuredImage: string;
}

interface FilterData {
    category: string[];
    size: string[];
    priceLength: [];
    sort: string;
}

const Main: FC<PageCollection2Props> = ({ className = "" }) => {

    const [productData, setProductData] = useState<Product[]>([])
    const [filterData, setFilterData] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [allSize, setAllSize] = useState<any>([])
    const [allCategory, setAllCategory] = useState<any>([])
    const [priceLength, setPriceLength] = useState<any>([])
    const [sortOrder, setSortOrder] = useState<any>([])

    const getData = async () => {
        const response:any = await http.post('https://boonshop1989.com/BSBO/api/product/show-list', {size:30, page:currentPage});
        if(response.Data.Products != null){
            setProductData(response.Data.Products)
            setTotalPage(response.Data.Pages)
        }
    }

    const getFilter = async () => {
        const response:any = await http.post('/home.php');
        if(response?.allSize != null){
            setAllSize(response.allSize)
        }

        if(response?.allCategory != null){
            setAllCategory(response.allCategory)
        }

        if(response?.priceLength != null){
            setPriceLength(response.priceLength)
        }

        if(response?.sortOrder != null){
            setSortOrder(response.sortOrder)
        }
    }

    useEffect(() => {
        getFilter()
    }, [])

    useEffect(() => {
        getData()
    }, [filterData])
    
    useEffect(() => {
        getData()
    }, [currentPage])
    

    return (
        <div
            className={`nc-PageCollection2 ${className}`}
            data-nc-id="PageCollection2"
        >
            <div className="container py-8 lg:pb-16 lg:pt-10">
                <div className="space-y-10 lg:space-y-14 hidden lg:block">
                    <main>
                        {/* LOOP ITEMS */}
                        <div className="flex flex-col lg:flex-row">
                            <div className="lg:w-1/3 xl:w-1/4 pr-4">
                                <SidebarFilters 
                                    allSize={allSize} 
                                    allCategory={allCategory} 
                                    priceLength={priceLength} 
                                    sortOrder={sortOrder} 
                                    setFilterData={setFilterData}
                                />
                            </div>
                            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 border-t lg:border-t-0"></div>
                            <div className="flex-1 ">
                                <div className="flex-1 grid sm:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-10 ">
                                    {productData.map((item:any, index:number) => (
                                        <ProductCard data={item} key={index} />
                                    ))}
                                </div>

                                {/* PAGINATION */}
                                <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                                    <Pagination 
                                        setFilterData={setFilterData}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        totalPage={totalPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </main>
                </div>

                <div className="block lg:hidden">
                    <main>
                        {/* TABS FILTER */}
                        <TabFilters />

                        {/* LOOP ITEMS */}
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
                            {productData.map((item:any, index:number) => (
                                <ProductCard data={item} key={index} />
                            ))}
                        </div>

                        <ButtonPrimary loading>Show me more</ButtonPrimary>
                    </main>
                </div>

                {/* === SECTION 5 === */}
                {/* <hr className="border-slate-200 dark:border-slate-700 my-8" /> */}

                {/* <SectionSliderCollections /> */}
                {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

                {/* SUBCRIBES */}
                {/* <SectionPromo1 /> */}
            </div>
        </div>
    );
};

export default Main;
