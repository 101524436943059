import { useEffect, useId, useRef } from "react";
import clsx from "clsx"
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";

import ProductCard from "components/ProductCard2";

function Main(props:any)
{
    const productData = props.data
    const sliderRef = useRef(null);
    const id = useId();
    const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

    useEffect(() => {
        if (!sliderRef.current) {
            return () => { };
        }

        // @ts-ignore
        const OPTIONS: Glide.Options = {
            perView: 4,
            gap: 32,
            bound: true,
            breakpoints: {
                1280: {
                    perView: 4 - 1,
                },
                1024: {
                    gap: 20,
                    perView: 4 - 1,
                },
                768: {
                    gap: 20,
                    perView: 4 - 2,
                },
                640: {
                    gap: 20,
                    perView: 1.5,
                },
                500: {
                    gap: 20,
                    perView: 1.3,
                },
            },
        };

        let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
        slider.mount();
        return () => {
            slider.destroy();
        };
    }, [sliderRef, UNIQUE_CLASS, productData]);

    return (
        <div className={clsx([
            "nc-SectionSliderProductCard",
            props?.className
        ])}>
            <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
                <Heading
                    className={props?.headingClassName}
                    fontClass={props?.headingFontClassName}
                    rightDescText={props?.subHeading}
                    hasNextPrev
                >
                    {props?.heading || `New Arrivals`}
                </Heading>
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {productData.map((item:any, index:number) => (
                            <li key={index} className={`glide__slide ${props.itemClassName}`}>
                                <ProductCard data={item} />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Main