import { FC } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";

export interface PaginationProps {
  className?: string;
  currentPage?: number;
  setCurrentPage?: any;
  totalPage?: number;
  setFilterData?: Function;
}

const Pagination: FC<PaginationProps> = (props) => {
  const totalPage = props?.totalPage ?? 10
  const pageActive = (page:any) => {
    return (
      <span
        key={page.label}
        className={clsx([
          "hover:cursor-pointer inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white",
          twFocusClass
        ])}
      >
        {page.label}
      </span>
    )
  }

  const pageOther = (page:any) => {
    return (
      <Link key={page.label} to={`/product?page=${page.label}`}>
      <span
        className={clsx([
          "hover:cursor-pointer inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700",
          twFocusClass
        ])}
        onClick={()=>{
          props.setCurrentPage(page.label)
        }}
      >
        {page.label}
      </span>
      </Link>
    )
  }

  const renderPage = () => {
    const pages = []
    for(let i=1; i<=totalPage; i++){
      if(i == props.currentPage){
        pages.push(pageActive({label:i}))
      }else{
        pages.push(pageOther({label:i}))
      }
    }

    return pages
  }

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium`}
    >
      {renderPage()}
    </nav>
  );
};

export default Pagination;
